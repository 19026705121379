<template>
  <fw-layout mobile-ready :back-to-enable="false">
    <template #main-content>
      <fw-panel :title="$t('personalDetails')" featured>
        <template v-if="!loading" #toolbar>
          <fw-button type="link" @click.native="getWorkerDetails(true)">{{ $t('forceUpdate') }}</fw-button>
        </template>

        <fw-panel-info v-if="!loading && errors?.length" type="warning">
          Não foi possivel obter os dados.
        </fw-panel-info>
      </fw-panel>

      <fw-panel :title="$t('institutionalDetails')" boxed="lg" class="my-5" custom-class="bg-white flex flex-col gap-3">
        <ContentLoader v-if="loading" />
        <div v-if="!loading">
          <fw-label>{{ $t('title') }}</fw-label>
          <div>{{ personal?.title || $t('notDefined') }}</div>
        </div>

        <div v-if="!loading">
          <fw-label>{{ $t('employeeNumber') }}</fw-label>
          <div>{{ user.number || $t('notDefined') }}</div>
        </div>

        <div v-if="!loading">
          <fw-label>{{ $t('employeeCard') }}</fw-label>
          <div :class="{ 'text-primary': !personal?.disabled }">
            {{ personal?.disabled ? $t('notActive') : $t('active') }}
          </div>
        </div>
      </fw-panel>

      <fw-panel :title="$t('personalDetails')" boxed="lg" class="my-5" custom-class="bg-white">
        <ContentLoader v-if="loading" />
        <div v-else class="flex flex-col gap-3">
          <div>
            <fw-label>{{ $t('fullName') }}</fw-label>
            <div>{{ personal?.name || $t('notDefined') }}</div>
          </div>

          <div>
            <fw-label>{{ $t('gender') }}</fw-label>
            <div>{{ personal?.gender ? $t(`genderName.${personal?.gender}`) : $t('notDefined') }}</div>
          </div>

          <div>
            <fw-label>{{ $t('birthdate') }}</fw-label>
            <div>{{ personal?.born_at || $t('notDefined') }}</div>
          </div>

          <div>
            <fw-label>{{ $t('dependentsNumber') }}</fw-label>
            <div>{{ personal?.dependents || $t('notDefined') }}</div>
          </div>

          <div>
            <fw-label>{{ $t('nationality') }}</fw-label>
            <div>{{ personal?.nationality || $t('notDefined') }}</div>
          </div>
        </div>
      </fw-panel>

      <fw-panel
        :title="$t('personalContacts')"
        boxed="lg"
        class="my-5"
        custom-class="bg-white"
        :loading="savingData"
        after-loading-checked
        :loading-error="savingDataError"
      >
        <template v-if="!loading" #toolbar>
          <div class="flex gap-1 items-center">
            <div
              v-if="editMode.personalContacts && $v.$error"
              class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium"
            >
              <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
              <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
            </div>
            <div class="flex gap-5">
              <fw-button
                :type="!editMode.personalContacts ? 'link' : 'link-light'"
                :disabled="savingData"
                @click.native="toggleEditMode('personalContacts')"
              >
                {{ editMode.personalContacts ? $t('close') : $t('edit') }}
              </fw-button>
              <fw-button
                v-if="editMode.personalContacts"
                type="primary"
                :loading="savingData"
                @click.native="saveData('personalContacts')"
                >{{ $t('save') }}</fw-button
              >
            </div>
          </div>
        </template>

        <ContentLoader v-if="loading" />
        <div v-if="!loading" class="flex flex-col gap-3">
          <div>
            <fw-label>{{ $t('address') }}</fw-label>
            <div v-if="!editMode.personalContacts">{{ contacts?.address || $t('notDefined') }}</div>
            <template v-else>
              <TextInput
                v-model="contacts.address"
                :minlength="2"
                :maxlength="60"
                :help="$t('charsLimit', { limit: '60' })"
                :placeholder="$t('address')"
                :class="{
                  error: $v.contacts.address.$error
                }"
              ></TextInput>
              <fw-tip v-if="$v.contacts.address.$error" error>
                <span v-if="!$v.contacts.address.required">{{ $t('errors.requiredF', { field: $t('address') }) }}</span>
                <span v-else>{{ ($t('errors.invalidF'), { field: $t('address') }) }}</span>
              </fw-tip>
            </template>
          </div>

          <div>
            <fw-label>{{ $t('addressComplement') }}</fw-label>
            <div v-if="!editMode.personalContacts">
              {{ contacts?.address_complement || $t('notDefined') }}
            </div>
            <template v-else>
              <TextInput
                v-model="contacts.address_complement"
                class="mt-2"
                :minlength="2"
                :maxlength="40"
                :help="$t('charsLimit', { limit: '40' })"
                :placeholder="$t('addressComplement')"
                :class="{
                  error: $v.contacts.address_complement.$error
                }"
              ></TextInput>
              <fw-tip v-if="$v.contacts.address_complement.$error" error>
                {{ $t('errors.invalidF', { field: $t('addressComplement') }) }}
              </fw-tip>
            </template>
          </div>

          <div class="flex gap-4">
            <div>
              <fw-label custom-class="inline-flex gap-2"
                >{{ $t('postalCode') }}<fw-icon-checkbox-circle v-if="zipcodeValidatedSAP" class="w-5 h-5 text-primary"
              /></fw-label>
              <div v-if="!editMode.personalContacts">{{ contacts?.zipcode || $t('notDefined') }}</div>
              <template v-else>
                <TextInput
                  v-model="contacts.zipcode"
                  placeholder="XXXX-XXX"
                  :class="{
                    error: $v.contacts.zipcode.$error
                  }"
                ></TextInput>
                <fw-tip v-if="$v.contacts.zipcode.$error" error>
                  <span v-if="!$v.contacts.zipcode.required">{{
                    $t('errors.required', { field: $t('postalCode').toLowerCase() })
                  }}</span>
                  <span v-else>{{ $t('errors.invalid', { field: $t('postalCode').toLowerCase() }) }}</span>
                </fw-tip>
              </template>
            </div>

            <div>
              <fw-label>{{ $t('postalCodeLocal') }}</fw-label>
              <div>{{ contacts?.zipcode_local || $t('notDefined') }}</div>
            </div>
          </div>
          <div>
            <fw-button v-if="editMode.personalContacts" type="link" @click.native="validateZipcode"
              >Validar código postal</fw-button
            >
          </div>

          <div>
            <fw-label>{{ $t('homePhone') }}</fw-label>
            <div v-if="!editMode.personalContacts">{{ contacts?.home_phone || $t('notDefined') }}</div>
            <template v-else>
              <NumberInput
                v-model="contacts.home_phone"
                :disabled="!editMode.personalContacts"
                :class="{
                  error: $v.contacts.home_phone.$error
                }"
              >
              </NumberInput>

              <fw-tip v-if="$v.contacts.home_phone.$error" error>
                <span v-if="!$v.contacts.home_phone.required">{{
                  $t('errors.required', { field: $t('homePhone').toLowerCase() })
                }}</span>
                <span v-else>{{ $t('errors.invalid', { field: $t('homePhone').toLowerCase() }) }}</span>
              </fw-tip>
            </template>
          </div>

          <div class="flex gap-4">
            <div>
              <fw-label>{{ $t('phoneCountry') }}</fw-label>
              <div v-if="!editMode.personalContacts">{{ contacts?.country_code || $t('notDefined') }}</div>
              <PhoneCountriesSelect
                v-else
                :placeholder="$t('phoneCountry')"
                :input="contacts.country"
                @update="phoneCountryChanged"
              />
            </div>
            <div>
              <fw-label>{{ $t('phone') }}</fw-label>
              <div v-if="!editMode.personalContacts">{{ contacts?.phone || $t('notDefined') }}</div>
              <template v-else>
                <NumberInput
                  v-model="contacts.phone"
                  :placeholder="$t('phone')"
                  :class="{
                    error: $v.contacts.phone.$error
                  }"
                ></NumberInput>
                <fw-tip v-if="$v.contacts.phone.$error" error>
                  <span v-if="!$v.contacts.phone.required">{{
                    $t('errors.required', { field: $t('phone').toLowerCase() })
                  }}</span>
                  <span v-else>{{ $t('errors.invalid', { field: $t('phone').toLowerCase() }) }}</span>
                </fw-tip>
              </template>
            </div>
          </div>

          <div>
            <fw-label>{{ $t('personalAndRecoverEmail') }}</fw-label>
            <div v-if="!editMode.personalContacts">{{ contacts?.email || $t('notDefined') }}</div>
            <template v-else>
              <TextInput
                v-model="contacts.email"
                :minlength="2"
                :maxlength="50"
                :class="{
                  error: $v.contacts.email.$error
                }"
              >
              </TextInput>

              <fw-tip v-if="$v.contacts.email.$error" error>
                <span v-if="!$v.contacts.phone.required">{{ $t('errors.required', { field: 'email' }) }}</span>
                <span v-else>{{ $t('errors.invalid', { field: 'email' }) }}</span>
              </fw-tip>
            </template>
          </div>
        </div>
      </fw-panel>

      <fw-panel :title="$t('institutionalContacts')" boxed="lg" class="my-5" custom-class="bg-white">
        <template v-if="!loading" #toolbar>
          <div class="flex gap-1 items-center">
            <div
              v-if="editMode.institutionalContacts && $v.$error"
              class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium"
            >
              <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
              <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
            </div>
            <div class="flex gap-5">
              <fw-button
                :type="!editMode.institutionalContacts ? 'link' : 'link-light'"
                :disabled="savingData"
                @click.native="toggleEditMode('institutionalContacts')"
              >
                {{ editMode.institutionalContacts ? $t('close') : $t('edit') }}
              </fw-button>
              <fw-button
                v-if="editMode.institutionalContacts"
                type="primary"
                :loading="savingData"
                @click.native="saveData('institutionalContacts')"
                >{{ $t('save') }}</fw-button
              >
            </div>
          </div>
        </template>

        <ContentLoader v-if="loading" />
        <div v-else class="flex flex-col gap-3">
          <div>
            <fw-label>{{ $t('homePhone') }}</fw-label>
            <div v-if="!editMode.institutionalContacts">{{ contacts?.org_phone || $t('notDefined') }}</div>
            <template v-else>
              <NumberInput
                v-model="contacts.org_phone"
                :max="999999999"
                :help="$t('charsLimit', { limit: '9' })"
                :class="{
                  error: $v.contacts.org_phone.$error
                }"
              ></NumberInput>
              <fw-tip v-if="$v.contacts.org_phone.$error" error>
                {{ ($t('errors.invalid'), { field: $t('homePhone') }) }}
              </fw-tip>
            </template>
          </div>
          <div>
            <fw-label>{{ $t('extension') }}</fw-label>
            <div v-if="!editMode.institutionalContacts">{{ contacts?.org_extension || $t('notDefined') }}</div>
            <template v-else>
              <NumberInput
                v-model="contacts.org_extension"
                :max="999999"
                :help="$t('charsLimit', { limit: '6' })"
                :class="{
                  error: $v.contacts.org_extension.$error
                }"
              ></NumberInput>
              <fw-tip v-if="$v.contacts.org_extension.$error" error>
                <span v-if="!$v.contacts.org_extension.max">{{ $t('errors.maxChars', { max: '9' }) }}</span>
                <span v-else>{{ ($t('errors.invalid'), { field: $t('extension') }) }}</span>
              </fw-tip>
            </template>
          </div>
          <div>
            <fw-label>Fax</fw-label>
            <div v-if="!editMode.institutionalContacts">{{ contacts?.org_fax || $t('notDefined') }}</div>
            <template v-else>
              <NumberInput
                v-model="contacts.org_fax"
                :max="999999999"
                placeholder="Fax"
                :help="$t('charsLimit', { limit: '9' })"
                :class="{
                  error: $v.contacts.org_fax.$error
                }"
              ></NumberInput>
              <fw-tip v-if="$v.contacts.org_fax.$error" error>
                <span v-if="!$v.contacts.org_fax.max">{{ $t('errors.maxChars', { max: '9' }) }}</span>
                <span v-else>{{ ($t('errors.invalid'), { field: 'Fax' }) }}</span>
              </fw-tip>
            </template>
          </div>

          <fw-panel-info class="mt-4" clean>{{ $t('comunicationPlatforms') }}</fw-panel-info>

          <div v-if="user.meeting">
            <fw-label>{{ $t('idUcMeetings') }}</fw-label>
            <div class="flex items-center gap-3">
              {{ user.meeting?.key_alias }}
              <fw-icon-virtual-meeting
                class="h-6 w-6 text-primary"
                :class="{ 'animate-pulse': user.meeting.running }"
              ></fw-icon-virtual-meeting>
              <fw-icon-chats class="h-6 w-6 text-primary"></fw-icon-chats>
            </div>
          </div>

          <div>
            <fw-label>ID skype</fw-label>
            <div v-if="!editMode.institutionalContacts">{{ contacts?.org_skype || $t('notDefined') }}</div>
            <template v-else>
              <TextInput
                v-model="contacts.org_skype"
                :maxlength="50"
                :help="$t('charsLimit', { limit: '50' })"
                placeholder="ID skype"
                :class="{
                  error: $v.contacts.org_skype.$error
                }"
              ></TextInput>
              <fw-tip v-if="$v.contacts.org_skype.$error" error>
                <span v-if="!$v.contacts.org_skype.max">{{ $t('errors.maxChars', { max: '50' }) }}</span>
                <span v-else>{{ ($t('errors.invalid'), { field: 'ID Skype' }) }}</span>
              </fw-tip>
            </template>
          </div>
          <div>
            <fw-label>ID Mircrosoft Teams</fw-label>
            <div v-if="!editMode.institutionalContacts">{{ contacts?.org_teams || $t('notDefined') }}</div>
            <template v-else>
              <TextInput
                v-model="contacts.org_teams"
                :maxlength="50"
                placeholder="ID Mircrosoft Teams"
                :help="$t('charsLimit', { limit: '50' })"
                :class="{
                  error: $v.contacts.org_teams.$error
                }"
              ></TextInput>
              <fw-tip v-if="$v.contacts.org_teams.$error" error>
                <span v-if="!$v.contacts.org_teams.max">{{ $t('errors.maxChars', { max: '50' }) }}</span>
                <span v-else>{{ ($t('errors.invalid'), { field: 'ID Mircrosoft Teams' }) }}</span>
              </fw-tip>
            </template>
          </div>
        </div>
      </fw-panel>

      <fw-panel :title="$t('bankDetails')" boxed="lg" class="my-5" custom-class="bg-white">
        <ContentLoader v-if="loading" />
        <div v-else class="flex flex-col gap-3">
          <div>
            <fw-label>{{ $t('bank') }}</fw-label>
            <div>{{ bank?.name || $t('notDefined') }}</div>
          </div>

          <div>
            <fw-label>IBAN</fw-label>
            <div>{{ bank?.iban || $t('notDefined') }}</div>
          </div>
        </div>
      </fw-panel>
      <fw-panel :title="$t('emailService')" boxed="lg" class="my-5" custom-class="bg-white font-semibold">
        <fw-panel-info centered clean
          >{{ $t('emailServiceInfo') }}
          <a href="https://apps.uc.pt/account" class="text-primary">https://apps.uc.pt/account</a>.
        </fw-panel-info>
      </fw-panel>

      <fw-panel-info debug label="Worker data (raw)">
        <json-viewer :value="{ personal, contacts, bank, institutional, errors, $v }"></json-viewer>
      </fw-panel-info>
    </template>
  </fw-layout>
</template>

<script>
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import PhoneCountriesSelect from '@/fw-modules/fw-core-vue/ui/components/form/PhoneCountriesSelect'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { postalCode } from '@/fw-modules/fw-core-vue/utilities/vuelidateHelpers'
import { required, email, minLength, maxLength, numeric } from 'vuelidate/lib/validators'

export default {
  components: {
    TextInput,
    ContentLoader,
    PhoneCountriesSelect,
    NumberInput
  },

  data() {
    return {
      editMode: {
        institutionalContacts: false,
        personalContacts: false
      },
      savingData: false,
      savingDataError: false,
      loading: true,
      personal: {},
      contacts: {},
      bank: {},
      institutional: {},
      zipcodeValidatedSAP: false,
      errors: []
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    }
  },

  mounted() {
    this.getWorkerDetails()
  },

  validations: {
    contacts: {
      address: { required, max: maxLength(60), min: minLength(2) },
      address_complement: { max: maxLength(40) },
      zipcode: { required, postalCode },
      country: { required },
      home_phone: { max: maxLength(14) },
      phone: { required, max: maxLength(14) },
      email: { required, email, min: minLength(2), max: maxLength(241) },
      org_phone: { max: maxLength(9), numeric },
      org_fax: { max: maxLength(9), numeric },
      org_extension: { max: maxLength(6), numeric },
      org_skype: { max: maxLength(50) },
      org_teams: { max: maxLength(50) }
    }
  },

  methods: {
    async getWorkerDetails(skipCache = false) {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getUserInfo(skipCache)
        console.log('getUserInfo :>> ', response)
        this.personal = response.personal
        this.contacts = response.contacts
        if (this.contacts && !this.contacts.country) {
          this.contacts.country = {
            key: 'PT',
            code: '+351',
            title: 'Portugal'
          }
        }
        this.bank = response.bank
        this.institutional = response.institutional

        if (response.__errors__ && response.__errors__.length > 0) {
          const apiError = utils.errors(response).get()
          if (apiError && apiError.key && apiError.key == 'SAPError') {
            this.errors.push(apiError.field)
          }
        }
      })

      this.loading = false
    },

    async updateDetails() {
      this.savingData = true
      this.savingDataError = false

      const tmpContacts = JSON.parse(JSON.stringify(this.contacts))
      tmpContacts.country = this.contacts.country ? this.contacts.country.key : null
      tmpContacts.phone = this.contacts.phone ? this.contacts.phone : null

      try {
        const response = await this.api.updateUserInfo(tmpContacts)
        console.log('updateUserInfo :>> ', response)

        this.$buefy.snackbar.open({
          message: this.$t('personalContactsSaved'),
          type: 'is-success',
          position: 'is-top-right',
          duration: 2000,
          queue: true
        })
      } catch (e) {
        this.savingDataError = true
        const errorKey = utils.errors(e).getKey()
        console.error(e)
        this.$buefy.dialog.alert({
          title: 'Ocorreu um erro',
          message: `<div class="mb-3">Ocorreu um erro não esperado a enviar os contactos para o sistema de referência. Por favor, tente novamente mais tarde.
					Caso o problema persista, por favor, contacte a nossa equipa de suporte.</div>
					<div class="text-sm opacity-80">Error key: ${errorKey}</div>`,
          type: 'is-danger'
        })
      }

      this.savingData = false
    },

    async validateZipcode() {
      this.savingData = true
      this.savingDataError = false

      try {
        const response = await this.api.validateSAPzipcodes(this.contacts.zipcode)
        console.log('validateZipcode :>> ', response)
        this.zipcodeValidatedSAP = true
        this.contacts.zipcode = response.code
        this.contacts.zipcode_local = response.local

        this.$buefy.snackbar.open({
          message: 'Código postal válido!',
          type: 'is-success',
          position: 'is-top-right',
          duration: 2000,
          queue: true
        })
      } catch (e) {
        this.savingDataError = true
        console.error(e)
        this.$buefy.toast.open({
          message: 'Código postal inválido',
          type: 'is-danger',
          duration: 4000,
          queue: true
        })
      }

      this.savingData = false
    },

    phoneCountryChanged(country) {
      console.log('phoneCountryChanged', country)
      this.$set(this.contacts, 'country', country)
    },

    saveData() {
      console.log('saveData invalid: ', this.$v.$invalid)
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.updateDetails()
      }
    },

    toggleEditMode(section) {
      this.editMode[section] = !this.editMode[section]
    }
  }
}
</script>

<i18n>
{
  "pt": {
		"forceUpdate": "Atualizar dados",
    "personalDetails": "Dados pessoais",
    "personalContacts": "Contactos pessoais",
    "institutionalDetails": "Dados institucionais",
    "institutionalContacts": "Contactos institucionais",
    "bankDetails": "Dados bancários",
    "emailService": "Serviço de email",
    "thereAreErrors": "Existem erros no formulário",
    "title": "Título",
    "employeeNumber": "Número de funcionário",
    "employeeCard": "Cartão de funcionário",
    "active": "Ativo",
    "notActive": "Não ativo",
    "fullName": "Nome completo",
    "gender": "Género",
    "address": "Morada",
    "addressComplement": "Morada 2",
    "postalCode": "Código postal",
    "postalCodeLocal": "Localidade",
    "genderName": {
      "M": "Masculino",
      "F": "Feminino"
    },
    "birthdate": "Data de nascimento",
    "civilStatus": "Estado Civil",
    "dependentsNumber": "Nº de dependentes",
    "nationality": "Nacionalidade",
    "homePhone": "Telefone",
    "phoneCountry": "Indicativo do país",
    "phone": "Telemóvel",
    "personalAndRecoverEmail": "Email pessoal e de recuperação de password",
    "extension": "Extensão",
    "comunicationPlatforms": "Plataformas de comunicação",
    "idUcMeetings": "ID UC Meetings (sala virtual, canais de conversação)",
    "bank": "Banco",
    "emailServiceInfo": "As configurações do serviço de email estão disponíveis em",
    "close": "Fechar",
    "edit": "Editar",
    "save": "Guardar",
    "delete": "Remover",
    "charsLimit": "Limite de {limit} caracteres.",
    "notDefined": "Não definido.",
    "one": "um",
    "personalContactsSaved": "Contactos pessoais guardados com successo",
    "errors": {
      "loading":"Ocorreu um erro ao obter os dados pessoais",
      "saving":"Ocorreu um erro ao guardar os contactos pessoais",
      "required": "Insira o {field}",
      "requiredF": "Insira a {field}",
      "requiredAtLeast": "Insira pelo menos {number} {field}.",
      "invalid": "Insira um {field} válido",
      "invalidF": "Insira uma {field} válida",
			"maxChars": "Insira no máximo {max} caracteres."
    }
  },
  "en": {
		"forceUpdate": "Force update",
    "personalDetails": "Personal details",
    "institutionalDetails": "Institutional details",
    "personalContacts": "Personal contacts",
    "institutionalContacts": "Institutional contacts",
    "bankDetails": "Bank details",
    "emailService": "Email service",
    "emailServiceInfo": "The email service settings are available at",
    "title": "Title",
    "employeeNumber": "Employee number",
    "employeeCard": "Employee Card",
    "active": "Active",
    "notActive": "Not active",
    "fullName": "Full name",
    "gender": "Gender",
    "address": "Address",
    "addressComplement": "Address 2",
    "postalCode": "Zip code",
    "postalCodeLocal": "Location",
    "genderName": {
      "M": "Male",
      "F": "Female"
    },
    "birthdate": "Date of birth",
    "civilStatus": "Civil status",
    "dependentsNumber": "No. of dependents",
    "nationality": "Nationality",
    "homePhone": "Home phone",
    "phoneCountry": "Country code",
    "phone": "Phone",
    "personalAndRecoverEmail": "Personal email and password recovery",
    "extension": "Extension",
    "comunicationPlatforms": "Communication platforms",
    "idUcMeetings": "ID UC Meetings (virtual room, chat channels)",
    "bank": "Bank",
    "thereAreErrors": "There are errors in the form",
    "close": "Close",
    "edit": "Edit",
    "save": "Save",
    "delete": "Remove",
    "date": {
      "label": "Date",
      "placeholder": "Choose a date"
    },
    "charsLimit": "Limit of {limit} characters.",
    "yes": "Yes",
    "no": "No",
    "notDefined": "Não definido.",
    "one": "one",
    "personalContactsSaved": "Personal contacts successfully saved",
    "errors": {
      "loading":"An error occurred while obtaining the personal data",
      "saving":"An error occurred when saving personal contacts",
      "required": "Enter the {field}",
      "requiredF": "Enter the {field}",
      "requiredAtLeast": "Enter at least {number} {field}.",
      "invalid": "Enter a valid {field}",
      "invalidF": "Enter a valid {field}"
    }
  }
}
</i18n>
